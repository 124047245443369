<template>
  <div class="ready-root">
    <div style="width: 1100px; margin: 0 auto">
      <div class="ready-text">
        {{$t('m.readyTip')}}
      </div>
      <div style="color: #1B213D; margin-top: 20px">
        {{$t('m.readyTip1')}}
      </div>
      <div class="check-container">
        <div class="checkItem">
          <div class="checkImteText">
            <img
              v-if="device_camera_status"
              src="../../assets/device_success.png"
              class="ready-img"
            />
            <img
              v-if="!device_camera_status"
              src="../../assets/device_error.png"
              class="ready-img"
            />
            <div class="ready-text" style="margin-left: 15px; font-size: 18px">
              {{$t('m.readyTip2')}}
            </div>
          </div>
          <div
            v-if="!device_camera_status"
            class="ready-restart"
            @click="checkDevice"
          >
          {{$t('m.readyTip3')}}
          </div>
        </div>

        <div class="checkItem">
          <div class="checkImteText">
            <img
              v-if="device_screen_status"
              src="../../assets/device_success.png"
              class="ready-img"
            />
            <img
              v-if="!device_screen_status"
              src="../../assets/device_error.png"
              class="ready-img"
            />
            <div class="ready-text" style="margin-left: 15px; font-size: 18px">
              {{$t('m.readyTip4')}}
            </div>
          </div>
          <div
            v-if="!device_screen_status"
            class="ready-restart"
            @click="checkDevice"
          >
          {{$t('m.readyTip3')}}
          </div>

          <div style="margin-top: 10px;font-size: 14px;">
            When authorizing screen sharing, you must select to share the entire screen, not just an application window or a browser tab.
          </div>


        </div>
        <div class="checkItem">
          <div class="checkImteText">
            <img
              v-if="idCheckSuccess"
              src="../../assets/device_success.png"
              class="ready-img"
            />
            <img
              v-if="!idCheckSuccess"
              src="../../assets/device_error.png"
              class="ready-img"
            />
            <div class="ready-text" style="margin-left: 15px; font-size: 18px">
              {{$t('m.readyTip5')}}
            </div>
          </div>
          <div
            v-if="idCheckSuccess"
            class="ready-restart"
            @click="showIdPicDialog = true"
          >
          {{$t('m.readyTip6')}}
          </div>
          <div class="ready-restart" @click="doShowIdDialog">
            {{$t('m.readyTip7')}}
          </div>
        </div>
      </div>
      
      <div style="color: #1B213D; margin-top: 50px; line-height: 200%">
        If you failed the [Camera Sharing Check] and [Full Screen Sharing Check], 
        please download this <a
          href="https://ap.learningfirst.tech/Equipment%20Setting%20Guide%20English.pdf"
          target="_blank"
          style="color: red;"
          ><span
            style="
              /* background-color: white; */
              color: red;
              margin-left: 5px;
              padding: 4px;
              border-radius: 4px;
            "
            > Equipment Guide</span
          ></a> and follow the instructions within to set up the security and privacy for both the 
        <span style="color: red;">[Broswer]</span> and the <span style="color: red;">[Operating System]</span> accordingly.
      </div>
      <!-- <div style="color: #1B213D; margin-top: 50px; line-height: 200%">
        {{$t('m.readyTip8')}} <br />**
        {{$t('m.readyTip9')}}
        <br />{{$t('m.readyTip10')}}
        <br />{{$t('m.readyTip11')}}
      </div>
      <div style="color: #1B213D; margin-top: 20px; line-height: 200%">
        {{$t('m.readyTip12')}}<br />{{$t('m.readyTip13')}}<a
          href="https://mainbucket.learningfirst.cn/Equipment%20Setting%20Guide%20English.pdf"
          target="_blank"
          ><span
            style="
              background-color: white;
              color: #1B213D;
              margin-left: 5px;
              padding: 4px;
              border-radius: 4px;
            "
            >{{$t('m.readyTip14')}}</span
          ></a
        >
      </div>
      <div style="color: #1B213D; margin-top: 20px">
        {{$t('m.readyTip15')}}
      </div> -->
      <div class="ready-go-exam" @click="goExam">{{$t('m.readyTip16')}}</div>
    </div>
    <canvas
      id="cameraCanvas"
      :width="cameraOptions.video.width"
      :height="cameraOptions.video.height"
      style="display: none"
    ></canvas>
    <canvas
      id="screenCanvas"
      :width="screenOptions.video.width"
      :height="screenOptions.video.height"
      style="display: none"
    ></canvas>

    <el-dialog
      :visible.sync="showUploadIdDialog"
      width="70%"
      :title="$t('m.readyTip17')"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form>
        <div class="camera-box">
          <div style="display: flex; flex-direction: row">
            <div>
              <div style="margin-bottom: 10px">{{$t('m.readyTip18')}}</div>
              <video
                id="id-video"
                :width="idVideoWidth"
                :height="idVideoHeight"
              ></video>
            </div>
            <div style="margin-left: 10px">
              <div style="margin-bottom: 10px">{{$t('m.readyTip19')}}</div>
              <canvas
                id="id-canvas"
                :width="idVideoWidth"
                :height="idVideoHeight"
              ></canvas>
            </div>
          </div>
          <p class="camera-p">
            {{
              $t('m.readyTip20')
            }}
          </p>
          <div style="margin-top: 15px; display: flex; flex-direction: row">
            <el-button type="primary" @click="setImage">{{
              imgSrc ? $t('m.readyTip21') : $t('m.readyTip17')
            }}</el-button>
            <el-button type="primary" @click="setFileUpload" v-if="imgSrc"
              >{{$t('m.readyTip22')}}</el-button
            >
          </div>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="showIdPicDialog" width="40%">
      <div style="text-align: center">
        <el-image :src="pic_url"></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkIsUploadTest,
  uploadPic,
  uploadStudentIdCard,
  getStudentIdCard,
} from "../../api/index";
import { getProjectCode, getUserId ,getProjectLanguage} from "../../utils/store";

export default {
  name: "ExamReady",
  data() {
    return {
      imgSrc: "",
      showUploadIdDialog: false,
      cameraOptions: {
        audio: false,
        video: { width: 500, height: 500 },
      },
      screenOptions: {
        audio: false,
        video: { width: 1280, height: 720 },
      },
      timer: "",
      status: false,
      have_exam: false,
      device_camera_status: true,
      device_screen_status: true,
      template_id: this.$route.query.template_id,
      start_time: this.$route.query.start_time,
      event_type: this.$route.query.event_type,
      cameraVideo: null, // 摄像头视频容器
      screenVideo: null, // 屏幕视频容器
      cameraStream: null, // 摄像头视频流
      screenStream: null, // 屏幕视频流

      idCanvas: undefined,
      idVideo: undefined,
      idContext: undefined,
      idVideoWidth: 400,
      idVideoHeight: 300,
      idCheckSuccess: false,
      idStream: null,
      idVideoStream: null,
      showIdPicDialog: false,
      pic_url: "",
      showChinese:getProjectLanguage()

    };
  },
  mounted() {
    this.checkStudentExam();
    this.startCheckOnline();
    this.checkDevice();
    this.checkStudentId();
  },
  beforeDestroy() {
    console.log("---beforeDestroy---");
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.clearAllResource();
  },
  methods: {
    checkStudentId() {
      getStudentIdCard(getUserId(), this.template_id, getProjectCode()).then(
        (res) => {
          var result = res.data.data;
          if (result.pic_url) {
            this.idCheckSuccess = true;
            this.pic_url = result.pic_url;
          }
        }
      );
    },
    doShowIdDialog() {
      this.imgSrc = "";
      this.showUploadIdDialog = true;
      this.$nextTick(() => {
        this.getCompetence();
      });
    },
    setImage() {
      var _this = this;
      // 点击，canvas画图
      _this.idContext.drawImage(
        _this.idVideo,
        0,
        0,
        _this.idVideoWidth,
        _this.idVideoHeight
      );
      // 获取图片base64链接
      var image = this.idCanvas.toDataURL("image/png");
      _this.imgSrc = image;
    },
    setFileUpload() {
      let param = new FormData();
      var time = new Date();
      var file_name = "id_pic_" + time.getTime();
      var file = this.dataURLtoFile(this.imgSrc, file_name);
      param.append("function", "uploadPic");
      param.append("file", file);
      uploadPic(param).then((res) => {
        console.log(res);
        var result = res.data.data;
        if (result) {
          this.doSavePic(result);
        } else {
          this.clearIdStream();
          this.$message.success(this.$t('m.idshangchuanshibai'));
        }
      });
    },
    doSavePic(pic_url) {
      uploadStudentIdCard(
        getUserId(),
        this.template_id,
        getProjectCode(),
        pic_url
      ).then((res) => {
        this.idCheckSuccess = true;
        this.pic_url = pic_url;
        this.$message.success(this.$t('m.idshangchaunchenggong'));
        this.showUploadIdDialog = false;
        this.clearIdStream();
      });
    },
    dataURLtoFile(dataUrl, fileName) {
      var arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    },
    getCompetence() {
      // 初始化的时候直接让为空
      this.imgSrc = "";
      var _this = this;
      this.idCanvas = document.getElementById("id-canvas");
      this.idContext = this.idCanvas.getContext("2d");
      this.idVideo = document.getElementById("id-video");
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      var constraints = {
        audio: false,
        video: {
          width: this.idVideoWidth,
          height: this.idVideoHeight,
          transform: "scaleX(-1)",
        },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          _this.idStream = stream;
          if ("srcObject" in _this.idVideo) {
            _this.idVideo.srcObject = stream;
            _this.idVideoStream = stream;
          } else {
            _this.idVideo.src = window.URL.createObjectURL(stream);
            _this.idVideoStream = stream;
          }
          _this.idVideo.onloadedmetadata = function (e) {
            _this.idVideo.play();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goExam() {
      if (this.have_exam) {
        this.$message.error(this.$t('m.yijingwanchengkaoshi'));
        return;
      }

      if (!this.status) {
        this.$message.error(this.$t('m.shebieyouwenti'));
        return;
      }

      if (!this.idCheckSuccess) {
        this.$message.error(this.$t('m.qingxianshangchuanshenfen'));
        return;
      }


      var currentTime = new Date().getTime();

      if (this.showChinese == 'english'){
         currentTime = this.getUTCTime(0).getTime()
      }

      if (this.event_type == "Formal") {
        if (this.start_time > currentTime) {
          this.$message({
            message: this.$t('m.kaoshishijianmeidao'),
            type: "warning",
          });
          return;
        }

        if (this.start_time + 7200000 < currentTime) {
          this.$message({
            message: this.$t('m.koashishijianguoshiwu'),
            type: "warning",
          });
          return;
        }
      }

      this.$router.replace({
        path: "/exam",
        query: {
          template_id: this.template_id,
          invigilateStatus:false
        },
      });
    },
    async checkDevice() {
      if (this.status) {
        return;
      }
      this.clearAllResource();
      // 判断浏览器是否有权限
      if (
        !navigator ||
        !navigator.mediaDevices ||
        !navigator.mediaDevices.getUserMedia
      ) {
        this.$message.error(this.$t('m.shexiangtouzhichi'));
        return console.log("浏览器不支持访问摄像头!");
      }
      if (
        !navigator ||
        !navigator.mediaDevices ||
        !navigator.mediaDevices.getDisplayMedia
      ) {
        this.$message.error(this.$t('m.pingmuluzhi'));
        return console.log("浏览器不支持屏幕录制!");
      }
      this.cameraVideo = document.createElement("video");
      this.screenVideo = document.createElement("video");
      // 视频流
      const loading = this.$loading({
        lock: true,
        text: this.$t('m.huoququanxianzhong'),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let stream = await navigator.mediaDevices
        .getUserMedia(this.cameraOptions)
        .catch((err) => {
          this.$message.error(` error:${err}`);
          this.device_camera_status = false;
          this.clearAllResource();
          return;
        })
        .finally(() => {
          loading.close();
        });
      this.cameraStream = stream;
      this.cameraVideo.srcObject = stream;
      this.cameraVideo.play();
      loading.close();
      // 屏幕流
      const loading1 = this.$loading({
        lock: true,
        text: this.$t('m.huoqupingmufenxiangzhong'),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let stream1 = await navigator.mediaDevices
        .getDisplayMedia(this.screenOptions)
        .catch((err) => {
          this.$message.error(` error:${err}`);
          this.device_screen_status = false;
          this.clearAllResource();
          return;
        })
        .finally(() => {
          loading1.close();
        });
      this.device_camera_status = true;
      if (
        stream1 &&
        stream1.getVideoTracks()[0].getSettings().displaySurface !== "monitor"
      ) {
        this.$alert(this.$t('m.qingxuanzhequanbupingmu'), this.$t('m.pingmugongxiangshiabia'), {
          confirmButtonText: "OK",
          type: "error",
        });
        this.device_screen_status = false;
        this.clearAllResource();
        return;
      }
      this.device_screen_status = true;
      this.screenStream = stream1;
      this.screenVideo.srcObject = stream1;
      this.screenVideo.play();
      this.status = true;
      loading.close();
    },

    getUTCTime(data){
      var time=new Date(); //获取时间
      // 获取时间偏移量 getTimezoneOffset 获取格林威治时间   *60000是到毫秒
      var dataOffset=new Date().getTimezoneOffset()*60000
      // 获取格林威治时间 
      var utc=time.getTime()+dataOffset;  // 两个时间戳
      // 拿格林威治时间去反推指定地区时间    
      var newTime=utc+3600000*data;  // 
      var times=new Date(newTime);
      return times
    },	
    startCheckOnline() {
      this.timer = setInterval(() => {
        if (!this.cameraStream || !this.cameraStream.active) {
          this.device_camera_status = false;
          if (this.status) {
            this.clearAllResource();
          }
          this.status = false;
        }
        if (!this.screenStream || !this.screenStream.active) {
          this.device_screen_status = false;
          if (this.status) {
            this.clearAllResource();
          }
          this.status = false;
        }
      }, 1000);
    },
    clearIdStream() {
      if (this.idStream && this.idStream.getTracks) {
        this.idStream.getTracks().forEach((track) => track.stop());
        this.idStream = null;
      }
      this.idVideoStream = null;
    },
    clearAllResource() {
      this.clearIdStream();
      if (this.cameraStream && this.cameraStream.getTracks) {
        this.cameraStream.getTracks().forEach((track) => track.stop());
      }
      if (this.screenStream && this.screenStream.getTracks) {
        this.screenStream.getTracks().forEach((track) => track.stop());
      }
      this.cameraVideo = null;
      this.screenVideo = null;
      this.cameraStream = null;
      this.screenStream = null;
      this.status = false;
    },
    checkStudentExam() {
      checkIsUploadTest(this.template_id, getUserId()).then((res) => {
        var result = res.data;
        this.have_exam = result;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ready-go-exam {
  cursor: pointer;
  margin-top: 11vh;
  text-align: center;
  width: 180px;
  height: 40px;
  background-color: #e64f29;
  opacity: 1;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 40px;
  color: white;
}

.ready-img {
  width: 32px;
  height: 32px;
}

.ready-restart {
  display: inline-block;
  cursor: pointer;
  background-color: #4468EC;
  color: white;
  font-family: "Microsoft YaHei";
  font-weight: bold;
  padding: 6px 12px;
  margin-top: 10px;
  margin-right: 10px;
}

.ready-text {
  font-size: 32px;
  color: #1B213D;
  font-family: "Microsoft YaHei";
  font-weight: bold;
}

.ready-root {
  width: 100vw;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
  background-image: url("../../assets/magin_right_bg.png");
  background-color: transparent;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .check-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .checkItem {
      border: 3px solid #e64f29;
      padding: 20px;
      box-sizing: border-box;
      width: 32%;
      .checkImteText {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
</style>
